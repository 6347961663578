import React from 'react';
import './Infiniti.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import tiers from '../images/tools.png';
import oil from '../images/oil.png';
import lamb from '../images/lambzz.png';
import patrol from '../images/patrol.png';
import nosmok from '../images/nosmok.png';
import filter from '../images/filter.png';
import breaks from '../images/breaks.png';
import ex from '../images/ex.png';
import red from '../images/red.png';
import fan from '../images/fan.png';


function Infiniti() {

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        draggable: false,
        pauseOnFocus: false,

        autoplay: true,
        speed: 2000,

        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    return (
        <div id="infinitiSlider">
            <Slider {...settings}>



                <div className="sliderBox">
                    <img src={tiers} alt="s" className="sliderContent" />
                </div>

                <div className="sliderBox">
                    <img src={oil} alt="s" className="sliderContent" />
                </div>
                <div className="sliderBox">
                    <img src={lamb} alt="s" className="sliderContent" />
                </div>

                <div className="sliderBox">
                    <img src={nosmok} alt="s" className="sliderContent" />
                </div>
                <div className="sliderBox">
                    <img src={tiers} alt="s" className="sliderContent" />
                </div>
                <div className="sliderBox">
                    <img src={filter} alt="s" className="sliderContent" />
                </div>
                <div className="sliderBox">
                    <img src={breaks} alt="s" className="sliderContent" />
                </div>
                <div className="sliderBox">
                    <img src={ex} alt="s" className="sliderContent" />
                </div>

                <div className="sliderBox">
                    <img src={red} alt="s" className="sliderContent" />
                </div>
                <div className="sliderBox">
                    <img src={fan} alt="s" className="sliderContent" />
                </div>
                <div className="sliderBox">
                    <img src={patrol} alt="s" className="sliderContent" />
                </div>
            </Slider>
        </div>
    );
}




export default Infiniti