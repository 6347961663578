import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import '../Slider/slider.css'
import rolls from '../images/rolls.jpg'
import bently from '../images/bently.jpg';
import jeep from '../images/jeep.jpg';
import ford from '../images/ford.jpg';
import porsch from '../images/porch.jpg';
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function Slider() {
    return (
        <>
            <div id="slider">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide><img src={bently} alt="logo" /> </SwiperSlide>

                    <SwiperSlide><img src={jeep} alt="logo" /> </SwiperSlide>

                    <SwiperSlide><img src={rolls} alt="logo" /> </SwiperSlide>
                    <SwiperSlide><img src={ford} alt="logo" /> </SwiperSlide>
                    <SwiperSlide><img src={porsch} alt="logo" /> </SwiperSlide>






                </Swiper>
            </div>
        </>
    );
}
