import React from 'react';
import '../VisionMission/Vm.css';
import vision from '../images/vision.png';
import mission from '../images/Mission.png';
import values from '../images/values.png';
import { useTranslation } from 'react-i18next';

function Vm() {
    const { t } = useTranslation();

    return (
        <>
            <div id="vm">

                <div id="v">

                    <div id="vimg-con">
                        <img id="vimg" src={vision} alt="Our Vision" />
                    </div>


                    <div id="vtitle">{t('Vision_Title')}</div>
                    <div align="justify" id="vdescription">
                        {t('Vision_Description')}
                    </div>

                </div>



                <div id="m">

                    <div id="mimg-con">
                        <img id="mimg" src={mission} alt="Our Mission" />
                    </div>

                    <div id="mtitle">{t('Mission_Title')} </div>
                    <div align="justify" id="mdescription">{t('Mission_Description')}</div>
                </div>





                <div id="vlaues">

                    <div id="vlauesimg-con">
                        <img id="valuesImg" src={values} alt="Our Values" />
                    </div>


                    <div id="valuestitle">{t('Values_Title')}</div>
                    <div align="justify" id="valuesdescription">{t('Values_Description')}</div>

                </div>
            </div>

        </>
    )
}

export default Vm