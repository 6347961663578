import React from 'react'
import '../About/about.css';
import { useTranslation } from 'react-i18next';

function About() {
    const { t } = useTranslation();
    return (
        <>
            <div id="about">
                <div id="title">{t('ABOUT_US_TITLE')}</div>
                <div align="justify" id="Aboutdescription">
                    {t('Aboutdescription')}
                </div>

            </div>




        </>
    )
}

export default About