import React from 'react';
import '../OurServices/OurServices.css';
import our from '../images/our.png';
import storeour from '../images/storeour.png';
import { useTranslation } from 'react-i18next';

function OurServices() {
    const { t } = useTranslation();

    return (
        <>
            <div id="services">
                <div id="servicesTitle">
                    {t('Services_Title')}

                </div>

                <img id="servicesImg" src={our} alt="our services"></img>
                <div id="servicesflex">
                    <img id="servicesImgStore" src={storeour} alt="our services"></img>

                    <div align="justify" id="servicesDesc">
                        {t('Services_Description')}

                    </div>


                </div>
                <button id="ourbutton">
                    {t('Start_Now_Button')}
                </button>
            </div>
        </>
    )
}

export default OurServices