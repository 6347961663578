import React from 'react';
import '../Header/header.css';
import { Link } from 'react-scroll'
import logo from '../images/HubLogo.png';
import logo2 from '../images/logoPhone.png';
import newLogo from '../images/newLogo.svg'
import { useTranslation } from 'react-i18next';
import LangSelector from '../LangSelector.js'


function Header() {
    const { t } = useTranslation();





    return (
        <>


            <nav>

                <img id="HUbLogo" src={newLogo} alt=""></img>
                <img id="HUbLogoPhone" src={newLogo} alt=""></img>

                <ul>
                    <li><Link activeClass="active" to="slider" spy={true} smooth={true}>Home</Link></li>
                    <li><Link to="about" spy={true} smooth={true}>{t('ABOUT_US_TITLE')}</Link></li>
                    <li><Link to="Appcontainer" spy={true} smooth={true}>Application</Link></li>
                    <li><Link to="services" spy={true} smooth={true}>Our services</Link></li>
                    <li><Link to="footers" spy={true} smooth={true}>Contact us</Link></li>
                </ul>


                <div className="object">
                    <div className="object-rope">

                    </div>
                    <div className="object-shape">

                        <br />

                        <Link to="Appcontainer" spy={true} smooth={true}>   <div id="login">Download<br />The App</div> </Link>
                    </div>
                </div>

                <LangSelector />

            </nav>


        </>
    )
}

export default Header