import React from 'react';
import './Footer.css'
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import { AiOutlineWhatsApp, AiOutlineMail } from 'react-icons/ai';
import footerLogo from '../images/logossv.svg';
import terms from '../images/terms.png';

function Footer() {
    var facebook = "https://www.facebook.com/people/Thecarhub-App/100079343146423/";
    var instagram = "https://www.instagram.com/accounts/login/?next=/thecarhubapp/";
    var email = "info@thecarhub.app"
    return (
        <>

            <footer>
                <div id="footers">

                    <div id="footLogo">

                        <img id="logoFooter" src={footerLogo} alt="" />
                        <div id="socialmedia">
                            <a href={facebook}> <FiFacebook /> </a>
                            <a href={instagram}> <FiInstagram /> </a>

                            <a href={"https://wa.me/+962781860702?text=Hello"}>  <AiOutlineWhatsApp /></a>

                            <a href="mailto:info@thecarhub.app"><AiOutlineMail /></a>

                        </div>


                    </div>

                </div>

            </footer>
            <div id="bootm">
                <div className="copy">  &copy; Copyright 2022</div>

                <div className="terms">
                    <img id="termsImg" src={terms} alt='' />

                    <div className="termsText">Terms of Use</div>
                </div>


            </div>



        </>
    )
}

export default Footer