
import React, { useState } from 'react';
import "swiper/css/bundle";

import './App.css';

import Header from './components/Header/header.js';
import Slider from './components/Slider/slider.js';
import About from './components/About/about.js';
import Vm from './components/VisionMission/Vm.js';
import Application from './components/Application/Application.js';
import { useTranslation } from 'react-i18next';

import OurServices from './components/OurServices/OurServices.js';
import Footer from './components/Footer/Footer.js';
import Infiniti from './components/Infiniti/Infiniti.js'


function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <div className="App">

      <Header />

      <Slider />

      <About />

      <Vm />
      <Application />
      <Infiniti />
      <OurServices />
      <Footer />

    </div>
  );
}

export default App;
