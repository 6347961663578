import React from 'react';
import iphone from '../images/iphone.png';
import '../Application/Application.css';
import googlePlay from '../images/google.png';
import appStore from '../images/Apple.png';
function Application() {
    return (
        <>

            <div id="Appcontainer">
                <div id="Apptitle">

                    The Car Hub
                </div>

                <div id="phoneIosAndroied">

                    <div id="Phone">
                        <img id="phone" src={iphone} alt="phone" />
                    </div>

                    <div id="Appdescription">
                        <div align="justify" id="description">
                            Fugiat anim voluptate est laborum aute fugiat velit qui eiusmod qui esse. Ut laboris commodo eiusmod duis aute. Nostrud enim consectetur ea incididunt. Ullamco non incididunt enim non minim magna ea enim tempor eiusmod. Deserunt enim cillum irure sint dolor id nostrud commodo ex. In esse irure aute non do eu dolore ullamco ipsum in eiusmod.

                        </div>
                        <div id="buttons">
                            <div id="Android">
                                <img src={googlePlay} className="download" alt="Google Play" />
                            </div>
                            <div id="ios">
                                <img src={appStore} className="download" alt="App Store" />
                            </div>
                        </div>
                    </div>
                </div>











            </div>




        </>
    )
}

export default Application